import React from "react";
import leftImage from "../../images/home/scooter.png";
import rightImage from "../../images/home/kaykay.png";
import withStyles from "@material-ui/core/styles/withStyles";
import { useInView } from "react-intersection-observer";

const boxSize = 17;
const styles = theme => ({
  root: {
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    paddingTop: "calc( 2vh + 2vw )",
    paddingBottom: "calc( 2vh + 2vw )",
    [theme.breakpoints.down("xs")]: {
      height: "70vh"
    }
  },
  rootVisible: {
    "& $content": {
      "& h2": {
        transform: "translateX(0)",
        opacity: "1",
        transitionDelay: " 0.3s"
      },
      "& p": {
        transform: "translateY(0)",
        opacity: "1",
        transitionDelay: " 0.6s"
      }
    },
    "& $box": {
      background: "#transparent",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      "&:before": {
        position: "absolute",
        content: "\"\"",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        borderTopColor: "#e7e9ea",
        borderRightColor: "#e7e9ea",
        transition: "width 0.1s ease-out, height 0.1s ease-out 0.1s"
      },
      "&:after": {
        position: "absolute",
        content: "\"\"",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        borderBottomColor: "#e7e9ea",
        borderLeftColor: "#e7e9ea",
        transition:
          "border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s"
      }
    }
  },
  content: {
    marginLeft: "40%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10%"
    },
    "& h2": {
      fontSize: "calc( 2vh + 2vw )",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2em"
      },
      margin: "0",
      fontWeight: 800,
      display: "inline-block",
      marginBlockStart: "0.3vw",
      opacity: "0",
      transition: "all .3s ease-out",
      transform: "translateX(20%)",
      transitionDelay: "0s"
    },

    "& p": {
      fontWeight: 300,
      fontSize: "calc( 1vh + 0.8vw )",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1em"
      },
      transition: "all .3s ease-out",
      transitionDelay: " 0s",
      opacity: "0",
      transform: "translateY(20%)",
      margin: "0"
    }
  },

  box: {
    position: "relative",
    zIndex: -4,
    top: "14vw",
    left: "21vw",
    content: "\"\"",
    width: `${boxSize * 1.2}vw`,
    height: `${boxSize}vw`,
    "&:before": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "0",
      top: "0",
      left: "0",
      height: "0",
      border: `1vw solid transparent`
    },
    "&:after": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "0",
      height: "0",
      border: `1vw solid transparent`,
      bottom: "0",
      right: "0"
    }
  },

  imgLeft: {
    opacity: "0.7",
    position: "absolute",
    left: "5%",
    height: "60vh",
    "-webkit-transform": "scaleX(-1)",
    transform: "scaleX(-1)",
    [theme.breakpoints.down("xs")]: {
      opacity: "0.1"
    }
  },
  imgRight: {
    opacity: "0.7",
    position: "absolute",
    right: "5%",
    height: "60vh",
    [theme.breakpoints.down("xs")]: {
      opacity: "0.1"
    }
  }
});

const ManifestCenter = ({ header, text, classes, showLeft = false, showRight = false }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.3
  });
  return (
    <div className={`${classes.root} ${!isVisible || classes.rootVisible}`} ref={ref}>
      {showLeft && <img className={classes.imgLeft} src={leftImage} alt=""/>}
      {showRight && <img className={classes.imgRight} src={rightImage} alt=""/>}
      <div style={{
        marginBottom: "14vw"
      }}>
        <div className={classes.box}/>
        <div className={classes.content}>
          <h2>{header}</h2>
          {text}
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(ManifestCenter);
