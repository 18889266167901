import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { colorPalette } from "../colors";
import { useInView } from "react-intersection-observer";

const boxSize = 20;
const styles = theme => ({
  root: {
    paddingTop: "15vw",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    alignItems: "top",
    justifyContent: "center",
    backgroundColor: "#EFF2F7",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  rootVisible: {
    "& $exp": {
      background: "#transparent"
    },
    "& $content": {
      "& p": {
        transform: "translateY(0)",
        opacity: "1",
        transitionDelay: " 0.6s"
      },
      "& h2": {
        transform: "translateX(0)",
        opacity: "1",
        transitionDelay: " 0.3s"
      },
      "& span": {
        width: "50%"
      }
    },
    "& $box": {
      background: "#transparent",
      "&:before": {
        position: "absolute",
        content: "\"\"",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        borderTopColor: "#ffffff",
        borderRightColor: "#ffffff",
        transition: "width 0.1s ease-out, height 0.1s ease-out 0.1s"
      },
      "&:after": {
        position: "absolute",
        content: "\"\"",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        borderBottomColor: "#ffffff",
        borderLeftColor: "#ffffff",
        transition:
          "border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s"
      }
    },
    "& $imgright": {
      "&:after": {
        right: "0",
        left: "auto"
      }
    },
    "& $imgContainer": {
      background: "transparent",

      "&:after": {
        width: "100%",
        zIndex: "0",
        transition: "all 0.3s ease-out 0.5s"
      },

      "& img": {
        background: "transparent",
        zIndex: "1",
        position: "relative",
        opacity: "1",
        transform: "translateX(0)",
        transition: "all .3s ease-out 0.5s"
      }
    }
  },
  imgright: {
    "&:after": {
      position: "absolute",
      content: "\"\"",
      left: "auto",
      right: "0"
    }
  },

  content: {
    marginTop: `-${boxSize}vw`,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: "30px",
      "& span": {
        left: 0
      }
    },
    position: "relative",
    "& h2": {
      fontSize: "calc( 2vh + 2vw )",
      lineHeight: "1.2em",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2em"
      },
      fontWeight: 800,
      display: "inline-block",
      marginBlockStart: "0.3vw",
      opacity: "0",
      transition: "all .3s ease-out",
      transform: "translateX(20%)",
      transitionDelay: "0s",
      margin: "0"
    },

    "& p": {
      fontWeight: 300,
      fontSize: "calc( 1vh + 0.8vw )",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1em"
      },
      transition: "all .3s ease-out",
      transitionDelay: " 0s",
      opacity: "0",
      transform: "translateY(20%)",
      margin: "0"
    },

    "& span": {
      width: "0%",
      float: "right",
      position: "absolute",
      top: "0",
      transition: "all .3s ease-out;",
      borderTop: `0.4vw solid ${colorPalette.orange}`
    }
  },

  box: {
    position: "relative",
    zIndex: -4,
    top: `-${boxSize / 7}vw`,
    content: "\"\"",
    [theme.breakpoints.down("xs")]: {
      display: "none",
      top: 0
    },
    width: `${boxSize * 1.2}vw`,
    height: `${boxSize}vw`,
    "&:before": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "0",
      top: "0",
      left: "0",
      height: "0",
      border: `1vw solid transparent`
    },
    "&:after": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "0",
      height: "0",
      border: `1vw solid transparent`,
      bottom: "0",
      right: "0"
    }
  },

  boxLeft: {
    left: "-5vw"
  },
  boxRight: {
    right: `-${boxSize * 1.5}vw`
  },

  imgContainer: {
    textAlign: "center",
    overflow: "visible",
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "0%",
      height: "100%",
      left: "0",
      transition: "all 0.3s ease-out",
      top: "0",
      backgroundColor: "#fff",
    },
    "& img": {
      width: "50%",
      marginTop: "-10vw",
      [theme.breakpoints.down("xs")]: {
        marginTop: "-30px"
      },
      zIndex: "1",

      marginBottom: "-5px",
      position: "relative",
      opacity: "0",
      transition: " all .3s ease-out 0s",
      transform: "translateX(-20%)"
    }
  },
  exp: {
    zIndex: 1
  },

  left: {
    order: 2,
    textAlign: "left",
    marginLeft: "-8vw",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      order: 0
    },
    paddingRight: "8vw",

    "& span": {
      float: "left"
    }
  },

  right: {
    textAlign: "right",
    marginRight: "-8vw",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      marginRight: 0,
      paddingLeft: 0
    },
    paddingLeft: "8vw",
    "& span": {
      right: "0"
    }
  }
});

const TwoColumn = ({
                     imageSrc=undefined,
                     imageWidth,
                     header,
                     text,
                     textFirst,
                     classes
                   }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.2
  });
  return (
    <div className={`${classes.root} ${!isVisible || classes.rootVisible}`} ref={ref}>
      <div
        className={`${classes.exp} ${
          textFirst ? classes.right : classes.left
        }`}
      >
        <div
          className={`${classes.box}  ${
            textFirst ? classes.boxRight : classes.boxLeft
          }`}
        />
        <div className={`${classes.content}`}>
          <span/>
          <h2>{header}</h2>
          {text}
        </div>
      </div>
      <div
        className={`${classes.imgContainer} ${
          textFirst ? classes.imgright : classes.imgleft
        }`}
      >
        <img src={imageSrc} alt="" style={{ width: imageWidth }}/>
      </div>
    </div>
  );
};

export default withStyles(styles)(TwoColumn);
